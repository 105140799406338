<template>
  <div class="right">
    <span class="spen">{{ $t('common.My_order') }}</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Dingdan' }" tag="li">{{ $t('common.Full_order') }}</router-link>
          <router-link :to="{ name: 'Fukuan' }" tag="li">{{ $t('common.due') }}</router-link>
          <router-link :to="{ name: 'Fahuo' }" tag="li">{{ $t('common.Goods_waiting_to_be_sent') }}</router-link>
          <router-link :to="{ name: 'Shouhuo' }" tag="li">{{ $t('common.Wait_to_be_received') }}</router-link>
          <router-link :to="{ name: 'Ping' }" tag="li">{{ $t('common.Remain_to_be_evaluated') }}</router-link>
          <router-link :to="{ name: 'Huishou' }" tag="li"
            >{{ $t('common.Order_recycling_station') }}</router-link
          >
        </ul>
      </div>
      <div class="search">
        <input
          type="text"
          v-model="mes"
          @keyup.enter.prevent="search"
          :placeholder="$t('placeholder.longmessage-004')"
        />
        <div class="fangdajing" @click="search">
          <Uimage src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            {{ $t('label.Order_number') }}：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            {{ $t('common.shop') }}：{{ item.storeName }}
          </div>
          <!-- <div class="biaoti mar kefu">
            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />
          </div> -->
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu" @click="xiangqing(item.storeId, res.spuId)">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- <div class="xiangsi">找相似</div> -->
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <div class="tousu">
            <span v-if="res.isTrue == 'true'">{{ $t('button.Complaint_seller') }}</span>
          </div>
          <div class="price">
            <div class="jiage">
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <!-- <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div> -->
            <div class="fangshi">{{ $t('button.Online_payment') }}</div>
          </div>
          <div class="zhuangtai">
            <div v-if="res.isTrue == 'true'">{{ $t('common.longmessage-005') }}</div>
            <div
              class="xiangqing"
              v-if="res.isTrue == 'true'"
              @click="ding_detail(res)"
            >
            {{ $t('common.Order_details') }}
            </div>
          </div>
          <div class="pay">
            <div class="liji" @click="fukuan(item)" v-if="res.isTrue == 'true'">
              {{ $t('button.Immediate_payment') }}
            </div>
            <!-- <div class="daifu">朋友代付</div> -->
            <div
              class="quxiao"
              @click="quxiao(res)"
              v-if="res.isTrue == 'true'"
            >
            {{ $t('button.Cancel_an_order') }}
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="order_list != ''">
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="totalNum"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        ></el-pagination> -->
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { setContextData, getContextData } from '../../../utils/session.js';
import { post, get } from '@/utils/request';
import { openPay } from '@/utils/pageUtils.js';
export default {
  name: 'Foot',
  props: {
    msg: String,
  },
  data() {
    return {
      order_list: [],
      isLoading: true,
      currentPage: 1,
      totalNum: 1,
      mes: '',
      tishi: this.$t('common.Its_empty'),
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
    };
  },
  created() {
    this.currentPage = getContextData('currentPage1') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    xiangqing(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    //店铺
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    pagechange(p) {
      this.page.current = p;
      this.isLoading = true;

      setContextData('currentPage1', this.currentPage);
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          p +
          '&pageSize=10&orderStatus=0'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          e +
          '&pageSize=10&orderStatus=0'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
    search() {
      this.isLoading = true;
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=0&orderNo=' + this.mes
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data.list == '') {
          this.tishi = this.$t('message.No_order_found');
          this.order_list = res.data.data.list;
        } else {
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
            req.orderSkuList.forEach((q) => {
              q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
              if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
              q.saleAttrCode.forEach((k, index) => {
                if (k.attrName == '浓度范围') {
                  q.saleAttrCode.splice(index, 1);
                }
              });
              Object.assign(q, { isTrue: 'false' });
            });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
          this.order_list = res.data.data.list;
          this.page.total = res.data.data.total;
        }
      });
    },
    //订单详情
    ding_detail(e) {
      this.$router.push({
        name: 'Detail_dingdan',
        query: {
          //skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    //付款
    fukuan(e) {
      // openPay(e);
      this.$router.push({
        name: 'Zhuanzhang',
        params: {
          price: e.orderAmount,
        },
        query: {
          orderId: e.orderId,
          isBuy:false
        },
      });
    },
    //取消订单
    quxiao(e) {
      this.$confirm(this.$t('message.shortmessage-033'), this.$t('common.Confirmation'), {
        confirmButtonText: this.$t('button.Confirm'),
        cancelButtonText: this.$t('button.Cancel'),
        type: 'warning',
      }).then(() => {
        this.isLoading = true;
        get('api/orderbasic/updateorder?orderId=' + e.orderId).then((res) => {
          this.getorder(this.currentPage);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;

      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 400px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    .search {
      margin-right: 150px;
      display: flex;
      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }
      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      padding-right: 10px;
      width: 345px;
      .title {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        .miaoshu {
          color: #333;
          margin-left: 10px;
          width: 190px;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          cursor: pointer;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .shuxing {
        margin-left: 10px;
        font-size: 12px;
        color: #9a9a9a;
        /* margin-top: 10px; */
        display: flex;
      }
    }
    .tousu {
      width: 102px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #333;
    }
    .price {
      width: 158px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      padding-left: 25px;
      .jiage {
        font-size: 14px;
        color: #333;
      }
      .yunfei {
        color: #a0a0a0;
        font-size: 12px;
      }
      .fangshi {
        color: #a0a0a0;
        font-size: 12px;
      }
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        cursor: pointer;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
      }
      .daifu {
        margin-top: 5px;
      }
      .quxiao {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: auto;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
